@import "../../styles/Variables.scss";

.list_item {

    @media screen and (max-width: 450px) {
        max-width: 100%;
        justify-content: center;

    }
}

.project {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    // background-color: $primary-1;
    width: 90%;
    max-width: 800px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 2%);
    box-shadow: 0px 0 10px rgb(0 0 0 / 8%);

    &__body {
        border: 1px solid gainsboro;
        border-top: 0px;
        display: flex;
        padding: 10px;
        flex-direction: column;
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;

    }

    &__title {
        padding-right: 7px;
        box-sizing: border-box;
        color: $primary;
        font-size: 20px;
        border-radius: 8px;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        border: 1px solid gainsboro;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
            background-color: rgb(223, 240, 255);
        }


        @media screen and (max-width: 450px) {
            flex-direction: column;
            align-items: flex-start
        }


    }

    &__users {
        display: flex;
        font-size: 14px;
        flex-wrap: wrap;

        &__user {
            outline: none;
            border: none;
            height: 40;
            margin: 5px;
            padding: 5px;
            padding-left: 10px;
            padding-right: 10px;
            background-color: white;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

            &:hover {
                background-color: rgb(245, 169, 169);
            }

            &:active {
                background-color: rgb(245, 158, 158);
            }
        }

        &__user-add {
            display: flex;
            outline: none;
            border: none;
            align-items: center;
            margin: 5px;

            padding-left: 10px;
            padding-right: 10px;
            background-color: rgb(155, 223, 164);
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

            &:hover {
                background-color: rgb(140, 218, 151);
            }

            &:active {
                background-color: rgb(155, 223, 164);
            }

            div {
                margin-right: 5px;
            }
        }

    }
}