.ant-upload-list-text-container {
    width: auto;
}

.ant-form-item {
    margin-bottom: 0;
}

.ant-upload-list-item {
    margin-top: 0px;
    margin-left: 5px;
}

.ant-upload-list {
    display: flex;
}

.task__card {


    // border-radius: 8px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    cursor: pointer;

    @media screen and (max-width: 450px) {
        margin-bottom: 20px !important;
        display: flex;

        width: 95%;
        overflow: hidden;
        // margin-left: 0 !important;
        // margin-right: 0 !important;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
    }

    &:active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    }

    .task {



        display: flex;
        flex-direction: row;
        justify-content: space-between;


        @media screen and (max-width: 450px) {
            flex-direction: column;
        }

        .delete_task_button {
            position: absolute;
            top: 0;
            right: 0;
            border: 1px solid #E4E9F5;
            border-top: none;
            border-right: none;
            padding-left: 2;
            padding-bottom: 2;
            border-end-start-radius: 20px;
            background: #E4E9E5;

            &:hover {
                background-color: #ebe0e0;
            }
        }

        &__left {
            width: 25%;
            display: flex;
            padding-right: 10px;
            padding-left: 10px;

            @media screen and (max-width: 450px) {
                width: 100%;
            }
        }

        // &__orgs {
        //     width: 90%;
        //     display: flex;
        //     flex-wrap: wrap;
        //     justify-content: space-around
        // }

        &__orgs-block {
            margin-bottom: 15px;
        }



        &__users {
            margin-top: 15px;
            width: 70%;
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            font-size: 11px;

            @media screen and (max-width: 450px) {
                margin-top: 10px;
            }

        }

        &__files {
            margin-top: 15px;
            width: 30%;
            display: flex;

            flex-wrap: wrap;

            @media screen and (max-width: 450px) {
                margin-top: 10px;
            }

        }

        &__org-title {
            font-weight: 600;
            color: #939393;
            font-size: 13px;
        }

        &__org-desc {
            font-size: 14px;

            @media screen and (max-width: 450px) {
                margin-bottom: 15px;
            }
        }

        &__org-files {
            display: flex;
        }

        &__org-name {
            font-size: 18px;
            font-weight: 450;
        }

        &__our-org-name {
            font-size: 18px;
            font-weight: 450;
        }

        &__center {
            margin-left: auto;
            width: 25%;
            padding-right: 10px;
            padding-left: 10px;


            @media screen and (max-width: 450px) {
                margin-left: 0;
                //   padding-left: 0;
                margin-top: 10px;
                width: 100%;
            }
        }

        &__amount {
            font-size: 20px;
            color: #9d9d9d;
            font-weight: 600;

            @media screen and (max-width: 450px) {
                display: block;
            }
        }

        &__stage {


            display: flex;

            align-items: center;

            @media screen and (max-width: 450px) {
                margin-top: 5px;
                width: 100%;

            }

        }

        &__status {
            display: inline-block;
            font-size: 15px;
            color: #414141;
            font-weight: 600;

            padding: 2px 10px;
            border-radius: 8px;
            background-color: #d1eaff;

            @media screen and (max-width: 450px) {
                display: block;

            }
        }

        &__debt {
            font-size: 19px;
            color: #ff5252;
            font-weight: 600;

            @media screen and (max-width: 450px) {
                display: block;
            }
        }

        &__stage_shipment {
            font-size: 16px;
            color: #b8865d;

            @media screen and (max-width: 450px) {
                display: block;
            }
        }

        &__right {
            margin-left: auto;
            padding-right: 10px;
            padding-left: 10px;
            width: 20%;

            @media screen and (max-width: 1650px) {
                width: 37%;
            }

            @media screen and (max-width: 1400px) {
                width: 35%;
            }

            @media screen and (max-width: 450px) {
                margin-left: 0;
                padding-left: 10;
                margin-top: 10px;
                width: 100%;
            }
        }
    }

    .ant-layout-content {
        @media screen and (max-width: 450px) {
            overflow-x: hidden;
        }
    }
}