.screen {
    width: 100%;
    height: 100%;

    .main__header {

        height: 50px;
        width: 100%;

    }

    .content {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;

        .content__table {
            width: 80%;
        }
    }
}

.create-button {
    margin-bottom: 16px;
    margin-top: -6px;
    font-weight: 500 !important;
}