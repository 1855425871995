.task-list {
    width: 80%;



    @media screen and (max-width: 1300px) {
        width: 100%;
        min-width: fit-content;
    }

    .filter-field {
        // margin-top: 20px;
        margin-bottom: 20px;
        align-items: baseline;
        // width: 450px;
        // @media screen and (max-width: 1400px) {
        //   width: 350px;
        // }
        // @media screen and (max-width: 450px) {
        //   width: 100%;
        // }

        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 450px) {
            gap: 16px;
            flex-direction: column;
        }

        &__item {
            width: 240px;
            margin-right: 16px;

            @media screen and (max-width: 450px) {
                margin-right: 0;
                width: 100%;
            }
        }

        &__segmented {
            width: 100%;

            @media screen and (max-width: 1660px) {
                margin-top: 16px;
            }

            @media screen and (max-width: 450px) {
                margin-top: 0;
            }
        }

        &--no-margin {
            margin-bottom: 0;
        }

        & .ant-radio-button-wrapper:first-child,
        & .ant-radio-button-wrapper:last-child {
            @media screen and (max-width: 450px) {
                border-radius: 0;
            }
        }

        & .ant-radio-group {
            @media screen and (max-width: 450px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        & .ant-radio-button-wrapper {
            @media screen and (max-width: 450px) {
                margin: 5px 0px;
                width: calc(50% - 5px);
                text-align: center;
                padding: 0;
                border-radius: 8px !important;
                border-left-width: 1px;
            }
        }

        & .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }


}

.form_text {
    color: "red"
}

.colorffb3b3 {
    background-color: #ffb3b3;
}

.colorffdccc {
    background-color: #ffdccc;
}

.colorfff5cc {
    background-color: #fff5cc;
}

.colorcce6ff {
    background-color: #cce6ff;
}

.colord9f2d9 {
    background-color: #d9f2d9;
}

// "#ffffff", "#ffb3b3", "#ffdccc", "#fff5cc", "#cce6ff", "#d9f2d9